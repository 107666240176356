<template>
  <div>
    <thux-table
      :instance="instance"
      :instance-name="instanceName"
      :order-by-dict="orderByDict"
      :goBack="goBack"
      :list="list"
      :fieldName="fieldName"
      :fields="fields"
      :fields-column="fieldsColumn"
      :select-all="selectAll"
      :selected-list="selectedList"
      :get-local-selected-all="getLocalSelectedAll"
      :local-selected-list="localSelectedList"
      @check-all="checkAll"
      @check-none="checkNone"
      @set-selected-row="setSelectedRow"
      :show-checks="showChecks"
      :showDetailButton="showDetailButton"
      @change-status="changeInstanceStatus"
      @sort-changed="orderTable"
      @remove-order-by="removeOrderFromTable"
      @open-detail-form="$emit('open-detail-form', $event)"
    >
      <template slot="ordering" slot-scope="row">
          <span v-if="row.item.in_yard_check_list">{{ row.item.ordering }}</span>
      </template>
      <template slot="contractor_summary_order" slot-scope="row">
          <span v-if="row.item.in_contractor_summary">{{ row.item.contractor_summary_order }}</span>
      </template>
      <template slot="other-buttons-actions-bottom" slot-scope="row">
        <b-btn
          variant="outline-primary"
          v-has-perms="goToConfigRolePerm"
          :title="'Configurations' | translate"
          @click.prevent="goToConfigPage(row.item.id)"
        >
          <i class="fas fa-cogs"></i>
        </b-btn>
        <b-btn
          v-if="row.item.is_downlodable"
          variant="outline-primary"
          v-has-perms="uploadEmptyDocRolePerm"
          :title="'Upload empty document' | translate"
          @click.prevent="showModal(row.item)"
        >
          <i class="fas fa-upload"></i>
        </b-btn>
        <b-btn
          v-if="row.item.file_to_download && row.item.is_downlodable"
          variant="outline-primary"
          v-has-perms="downloadModelRolePerm"
          :title="'Download model' | translate"
          @click.prevent="downloadModel(row.item)"
        >
          <i class="fas fa-download"></i>
        </b-btn>
      </template>
    </thux-table>
    <file-upload-modal
      v-if="showUploadModelModal && document && document.id"
      :title="$t('Upload empty document')"
      @submit="uploadDocumentModel"
      @close="closeModal"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as DOCUMENT_TYPES } from './store'
import { ROUTES as DOCUMENT_ROUTES } from './router'
import { hasPerm } from '@/components/PermissionHelper'

import ThuxTableMixin from '../../../components/thux-table/ThuxTableMixin'
import ThuxTableChecksMixin from '../../../components/thux-table/thux-table-checks/ThuxTableChecksMixin'
import FileUploadModal from '@/components/FileUploadModal'
import { storeMixin } from '@/storeMixin'

export default {
  name: 'ProductTable',
  components: { FileUploadModal },
  mixins: [ThuxTableMixin, ThuxTableChecksMixin],
  data () {
    return {
      fields: [
        {
          key: 'checks',
          label: this.$t('Checks')
        },
        {
          key: 'status',
          label: this.$t('Status'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: true,
          value: 'status'
        },
        {
          key: 'document_type',
          label: this.$t('Typology'),
          type: 'text',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: true,
          value: 'document_type_name'
        },
        {
          key: 'name',
          label: this.$t('Name'),
          type: 'text',
          thClass: 'text-nowrap',
          tdClass: 'text-wrap',
          sortable: true,
          value: 'name'
        },
        {
          key: 'acronym',
          label: this.$t('Acronym'),
          type: 'text',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: true,
          value: 'acronym'
        },
        {
          key: 'validity_duration',
          label: this.$t('Duration'),
          type: 'duration',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          showHours: false,
          sortable: true,
          value: 'validity_duration'
        },
        {
          key: 'in_yard_check_list',
          label: this.$t('Yard-ITP'),
          type: 'boolean',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          showHours: false,
          sortable: true,
          value: 'in_yard_check_list'
        },
        {
          key: 'ordering',
          label: this.$t('ITP order'),
          type: 'text',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: false,
          custom: true
        },
        {
          key: 'in_contractor_summary',
          label: this.$t('Organization summary'),
          type: 'boolean',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          showHours: false,
          sortable: true,
          value: 'in_contractor_summary'
        },
        {
          key: 'contractor_summary_order',
          label: this.$t('Organization summary order'),
          type: 'text',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: false,
          custom: true
        },
        {
          key: 'action',
          label: this.$t('Actions'),
          tdClass: 'text-right text-nowrap',
          thClass: 'text-right text-nowrap'
        }
      ],
      fieldsColumn: ['status', 'document_type', 'name', 'acronym'],
      document: undefined,
      showUploadModelModal: false,
      // Permissions
      downloadModelRolePerm: 'document_document_download_file',
      uploadEmptyDocRolePerm: 'document_document_update',
      goToConfigRolePerm: 'document_documentconfig_list'
    }
  },
  computed: {
    ...mapGetters({
      selectAll: DOCUMENT_TYPES.GENERIC.document.document.LIST.GETTERS.selectAll,
      selectedList: DOCUMENT_TYPES.GENERIC.document.document.LIST.GETTERS.selectedList
    }),
    showChecks () {
      return true
    },
    showDetailButton () {
      return hasPerm('document_document_retrieve')
    }
  },
  methods: {
    ...mapMutations({
      setList: DOCUMENT_TYPES.GENERIC.document.document.LIST.MUTATIONS.setList,
      setSelectedList:
        DOCUMENT_TYPES.GENERIC.document.document.LIST.MUTATIONS.setSelectedList,
      setSelectAll: DOCUMENT_TYPES.GENERIC.document.document.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...DOCUMENT_TYPES.GENERIC.document.document.LIST.ACTIONS,
      partialUpdate: DOCUMENT_TYPES.GENERIC.document.document.DETAIL.ACTIONS.partialUpdate,
      download: DOCUMENT_TYPES.GENERIC.document.document.DETAIL.ACTIONS.download
    }),
    goToConfigPage (itemId) {
      this.$router.push({ name: DOCUMENT_ROUTES.DOCUMENT_CONFIGURATION_LIST, params: { docId: itemId } })
    },
    showModal (item) {
      this.$set(this, 'document', item)
      this.$set(this, 'showUploadModelModal', true)
    },
    closeModal () {
      this.$set(this, 'document', null)
      this.$set(this, 'showUploadModelModal', false)
    },
    uploadDocumentModel (file) {
      let formData = { id: this.document.id }
      formData.file_to_download = file
      formData = storeMixin.utils.parseFormMultipart(formData, ['file_to_download'])
      this.partialUpdate(formData)
    },
    downloadModel (document) {
      this.download({ id: document.id, action: 'download-document', simpleFileName: document.file_to_download_filename })
    }
  }
}
</script>
