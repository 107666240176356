<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as DOCUMENT_TYPE_TYPES } from '../../document-type/store'
import { vue } from '@/main'

export default {
  name: 'DocumentTypeMixin',
  created () {},
  computed: {
    ...mapGetters({
      documentTypeList: DOCUMENT_TYPE_TYPES.GENERIC.document.documenttype.LIST.GETTERS.list
    }),
    documentTypeOptions () {
      if (this.documentTypeList) {
        return this.prepareListForSelect(this.documentTypeList.results, 'id', ['name'])
      }
      return []
    }
  },
  methods: {
    ...mapActions({
      getDocumentTypeList: DOCUMENT_TYPE_TYPES.GENERIC.document.documenttype.LIST.ACTIONS.setFilters
    }),
    ...mapMutations({
      setDocumentTypeList: DOCUMENT_TYPE_TYPES.GENERIC.document.documenttype.LIST.MUTATIONS.setList
    }),
    onSearchDocumentType (search) {
      vue.$store.commit('componentLoading', false)
      if (search.length >= 2) {
        const filters = {}
        filters.name__icontains = search
        filters.order_by = '-status'
        this.getDocumentTypeList(filters).then(() => {
          vue.$store.commit('componentLoading', true)
        })
      } else {
        this.setDocumentTypeList({})
        vue.$store.commit('componentLoading', true)
      }
    },
    selectDocumentType (item) {
      if (!this.form.document_type) {
        this.$set(this.form, 'document_type', null)
      }
      this.$set(this.form, 'document_type', item.value)
      this.$set(this.form, 'document_type_name', item.name)
    },
    clearDocumentType () {
      this.$set(this.form, 'document_type', null)
      this.$set(this.form, 'document_type_name', null)
    }
  }
}
</script>
