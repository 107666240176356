<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as PRODUCT_TYPES } from '../../../product/product/store'
import { vue } from '@/main'

export default {
  name: 'ProductMixin',
  created () {
    this.setProductList({})
  },
  computed: {
    ...mapGetters({
      productList: PRODUCT_TYPES.GENERIC.product.product.LIST.GETTERS.list
    }),
    productOptions () {
      if (this.productList) {
        return this.prepareListForMultiSelect(this.productList.results, 'id', ['code', 'name'])
      }
      return []
    }
  },
  methods: {
    ...mapActions({
      getProductList: PRODUCT_TYPES.GENERIC.product.product.LIST.ACTIONS.setFilters
    }),
    ...mapMutations({
      setProductList: PRODUCT_TYPES.GENERIC.product.product.LIST.MUTATIONS.setList
    }),
    onSearchProduct (search) {
      vue.$store.commit('componentLoading', false)
      if (search.length >= 2) {
        const filters = {}
        filters.filter__name__icontains = search
        filters.order_by = '-status'
        this.getProductList(filters).then(() => {
          vue.$store.commit('componentLoading', true)
        })
      } else {
        this.setProductList({})
        vue.$store.commit('componentLoading', true)
      }
    },
    getProducts (formData) {
      const values = []
      if (formData.products) {
        this.productOptions.forEach(element => {
          if (formData.products.includes(element.id)) {
            const name = element.code ? `${element.code} ${element.name}` : `${element.name}`
            values.push({
              id: element.id,
              name: name,
              status: element.status
            })
          }
        })
        return values
      }
    },
    setProducts (formData) {
      if (this.form.products) {
        const list = []
        this.form.products.forEach((row) => list.push(row.id))
        this.$set(formData, 'products', new Set(list))
      } else {
        this.$set(formData, 'products', [])
      }
    },
    removeProduct (item) {
      const products = this.form.products.filter((prod) => {
        return prod.id !== item.id
      })
      this.$set(this.form, 'products', products)
      if (this.form.products.length === 0) this.$set(this.form, 'products', null)
    },
    selectProduct (item) {
      if (!this.form.products) {
        this.$set(this.form, 'products', [])
      }
      this.form.products.push(item)
    }
  }
}
</script>
