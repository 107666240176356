<script>
import { mapActions, mapGetters } from 'vuex'
import { TYPES as DOCUMENT_TYPES } from '../store'

export default {
  name: 'SubtypeMixin',
  created () {
    if (!this.subtypeList || (this.subtypeList && this.subtypeList.length === 0)) {
      this.getSubtypeList()
    }
  },
  computed: {
    ...mapGetters({
      subtypeList: DOCUMENT_TYPES.GENERIC.document.document.LIST.GETTERS.subtypeList
    }),
    subtypeListOptions () {
      const options = []
      if (this.subtypeList && this.subtypeList.length > 0) {
        this.subtypeList.forEach((instance) => {
          options.push({
            value: instance[0],
            text: instance[1]
          })
        })
      }
      return options
    }
  },
  methods: {
    ...mapActions({
      getSubtypeList:
        DOCUMENT_TYPES.GENERIC.document.document.LIST.ACTIONS.getSubtypeList
    }),
    selectSubtype (item) {
      this.$set(this.form, 'subtype', item.value)
      this.$set(this.form, 'get_subtype_display', item.text)
    },
    clearSubtype () {
      this.$set(this.form, 'subtype', null)
      this.$set(this.form, 'get_subtype_display', null)
    }
  }
}
</script>
