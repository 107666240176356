
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as DOCUMENT_TYPES } from './store'
import { ROUTES as DOCUMENT_ROUTES } from './router'
import { hasPerm } from '@/components/PermissionHelper'

import ThuxListMixin from '@/components/thux-list/ThuxListMixin'

import DocumentEdit from './DocumentEdit'
import DocumentCommonFilters from './DocumentCommonFilters'
import DocumentTable from './DocumentTable'

export default {
  name: 'DocumentList',
  mixins: [ThuxListMixin],
  components: {
    'component-table': DocumentTable,
    'common-filters': DocumentCommonFilters,
    'component-edit': DocumentEdit,
    'component-detail': DocumentEdit
  },
  data () {
    return {
      DOCUMENT_ROUTES,
      title: this.$t('Documents'),
      rolePerm: ['document_document_list'],
      actionEnablePermission: ['document_document_enable'],
      actionDisablePermission: ['document_document_disable'],
      searchFields: [
        { key: 'filter__name__icontains', placeholder: this.$t('Name'), type: 'text', col: 6 },
        { key: 'filter__acronym__icontains', placeholder: this.$t('Acronym'), type: 'text', col: 6 }
      ]
    }
  },
  computed: {
    ...mapGetters({
      ...DOCUMENT_TYPES.GENERIC.document.document.LIST.GETTERS
    }),
    showList () {
      return this.$route.name === DOCUMENT_ROUTES.DOCUMENT_LIST
    },
    canSeeActionSelect () {
      return true
    },
    canAddInstance () {
      return hasPerm('document_document_create')
    }
  },
  methods: {
    ...mapMutations({
      setSelectedList: DOCUMENT_TYPES.GENERIC.document.document.LIST.MUTATIONS.setSellectedList,
      setSelectAll: DOCUMENT_TYPES.GENERIC.document.document.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...DOCUMENT_TYPES.GENERIC.document.document.LIST.ACTIONS
    })
  }
}
</script>
