<template>
  <panel :title="title" noButton="true" v-has-perms="rolePerm">
    <div>
      <b-form>
        <b-form-row>
          <form-thux-horizontal-autocomplete
            :validator="$v.form.document_type"
            :external-errors="errors['document_type']"
            :label-form="'Document type' | translate"
            class-form="col-xl-6 my-2"
            labelColsSm="2"
            labelColsLg="2"
            :disabled="!formEditable"
            label-search="name"
            :customize-option="true"
            :value="form.document_type ? { value: form.document_type, name: form.document_type_name } : null"
            :options="documentTypeOptions"
            @search="onSearchDocumentType"
            @select="selectDocumentType"
            @clear="clearDocumentType"
          />
          <form-thux-horizontal-input
            :validator="$v.form.name"
            :external-errors="errors['name']"
            :label-form="'Name' | translate"
            class-form="col-xl-6 my-2"
            type="text"
            :value="form.name"
            :disabled="!formEditable"
            @change="$set(form, 'name', $event)"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-input
            :validator="$v.form.acronym"
            :external-errors="errors['acronym']"
            :label-form="'Acronym' | translate"
            class-form="col-xl-6 my-2"
            type="text"
            :value="form.acronym"
            :disabled="!formEditable"
            @change="$set(form, 'acronym', $event)"
          />
          <form-thux-horizontal-input
            :validator="$v.form.description"
            :external-errors="errors['description']"
            :label-form="'Description' | translate"
            class-form="col-xl-6 my-2"
            type="text"
            :value="form.description"
            :disabled="!formEditable"
            @change="$set(form, 'description', $event)"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-duration
            :validator="$v.form.validity_duration"
            :external-errors="errors['validity_duration']"
            :label-form="'Duration' | translate"
            class-form="col-xl-6 my-2"
            :hours="false"
            type="text"
            :value="form.validity_duration"
            :disabled="!formEditable"
            @change="$set(form, 'validity_duration', $event)"
          />
          <form-thux-horizontal-select
            :validator="$v.form.subtype"
            :external-errors="errors['subtype']"
            :label-form="'Subtype' | translate"
            class-form="col-xl-6 my-2"
            :disabled="!formEditable"
            label-search="text"
            :value="form.subtype ? { value: form.subtype, text: form.get_subtype_display } : null"
            :placeholder="$t('Select an option')"
            :options="subtypeListOptions"
            @select="selectSubtype"
            @clear="clearSubtype"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-multiselect
            :validator="$v.form.products"
            label-form="Reference products"
            label-form-class="mb-2"
            :class-form="'col-lg-12 mb-3'"
            labelColsSm="2"
            labelColsLg="1"
            :external-errors="errors['products']"
            label-search="name"
            :disabled="!formEditable"
            :value="form.products"
            :options="productOptions"
            :placeholder="
              $t('Select a product')
            "
            @search="onSearchProduct"
            @remove="removeProduct"
            @select="selectProduct"
            @clear="$set(form, 'products', null)"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-checkbox
            :validator="$v.form.in_yard_check_list"
            :external-errors="errors['in_yard_check_list']"
            :label-form="'Yard-ITP' | translate"
            class-form="col-xl-2 my-2"
            label-cols-lg="6"
            label-cols-sm="3"
            :value="form.in_yard_check_list"
            :disabled="!formEditable"
            @change="$set(form, 'in_yard_check_list', $event)"
          />
          <form-thux-horizontal-input
            :validator="$v.form.ordering"
            :external-errors="errors['ordering']"
            :label-form="'ITP order' | translate"
            class-form="col-xl-4 my-2"
            label-cols-lg="4"
            label-cols-sm="2"
            type="number"
            :value="form.ordering"
            :disabled="!formEditable"
            @change="$set(form, 'ordering', $event)"
          />
          <form-thux-horizontal-checkbox
            :validator="$v.form.in_yard_evaluation"
            :external-errors="errors['in_yard_evaluation']"
            :label-form="'Yard-Summary' | translate"
            class-form="col-xl-4 my-2"
            label-cols-lg="4"
            label-cols-sm="3"
            :value="form.in_yard_evaluation"
            :disabled="!formEditable"
            @change="$set(form, 'in_yard_evaluation', $event)"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-checkbox
            :validator="$v.form.in_contractor_summary"
            :external-errors="errors['in_contractor_summary']"
            :label-form="'Organization summary' | translate"
            class-form="col-xl-2 my-2"
            label-cols-lg="6"
            label-cols-sm="3"
            :value="form.in_contractor_summary"
            :disabled="!formEditable"
            @change="$set(form, 'in_contractor_summary', $event)"
          />
          <form-thux-horizontal-input
            :validator="$v.form.contractor_summary_order"
            :external-errors="errors['contractor_summary_order']"
            :label-form="'Organization summary order' | translate"
            class-form="col-xl-4 my-2"
            label-cols-lg="4"
            label-cols-sm="2"
            type="number"
            :value="form.contractor_summary_order"
            :disabled="!formEditable"
            @change="$set(form, 'contractor_summary_order', $event)"
          />
        </b-form-row>
      </b-form>
      <div class="mt-3 f-w-600 d-flex justify-content-end">
        <div class="mt-3 f-w-600 d-flex justify-content-end">
          <b-button
            class="mr-3"
            variant="default"
            @click.prevent="formEditable ? cancel() : closeForm()">
            {{ formEditable ? 'Cancel' : 'Close' | translate }}
          </b-button>
          <b-button
            variant="primary"
            v-has-perms="editRolePerm"
            @click.prevent="formEditable ? onSubmit() : makeFormEditable()"
            :disabled="$v.form.$invalid">
            {{ formEditable ? 'Save' : 'Edit' | translate }}
          </b-button>
        </div>
      </div>
    </div>
  </panel>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as DOCUMENTTYPES } from './store'

import ThuxDetailPanelMixin from '@/components/thux-detail-panel/ThuxDetailPanelMixin'
import DocumentTypeMixin from './mixins/DocumentTypeMixin'
import SubtypeMixin from './mixins/SubtypeMixin'
import ProductMixin from './mixins/ProductMixin'
import { storeMixin } from '@/storeMixin'

export default {
  name: 'DocumentEdit',
  mixins: [ThuxDetailPanelMixin, DocumentTypeMixin, SubtypeMixin, ProductMixin],
  data () {
    return {
      pageName: 'Document',
      rolePerm: 'document_document_retrieve',
      editRolePerm: ['document_document_update', 'document_documenttype_list'],
      form: {
        in_yard_check_list: false,
        in_yard_evaluation: false,
        in_contractor_summary: false
      }
    }
  },
  computed: {
    ...mapGetters({
      ...DOCUMENTTYPES.GENERIC.document.document.DETAIL.GETTERS
    })
  },
  components: { },
  methods: {
    ...mapActions({
      ...DOCUMENTTYPES.GENERIC.document.document.DETAIL.ACTIONS
    }),
    ...mapMutations({
      setDocDetail: DOCUMENTTYPES.GENERIC.document.document.DETAIL.MUTATIONS.setDetail
    }),
    initFields () {
      if (!this.id) {
        this.$set(this.form, 'in_contractor_summary', false)
        this.$set(this.form, 'in_yard_check_list', false)
        this.$set(this.form, 'in_yard_evaluation', false)
      }
    },
    cancel () {
      if (!this.id) {
        this.closeForm()
      } else {
        const form = Object.assign({}, this.detail)
        if (form.products && form.products.length > 0) {
          const filters = {}
          filters.filter__id__in = form.products
          filters.order_by = '-status'
          this.getProductList(filters).then(() => {
            this.$set(form, 'products', this.getProducts(form))
            this.$set(this, 'form', form)
          })
        } else {
          this.$set(this, 'form', form)
        }

        this.formEditable = false
      }
    },
    setForm () {
      if (this.id) {
        const form = Object.assign({}, this.detail)
        if (form.products && form.products.length > 0) {
          const filters = {}
          filters.filter__id__in = [form.products]
          filters.order_by = '-status.-date_create'
          this.getProductList(filters).then(() => {
            this.$set(form, 'products', this.getProducts(form))
            this.$set(this, 'form', form)
          })
        } else {
          this.$set(this, 'form', form)
        }
      } else {
        this.setDocDetail({ products: [] })
      }
    },
    onSubmit () {
      let formData = this.getFormData()
      this.setProducts(formData)
      formData = storeMixin.utils.parseFormMultipart(formData, ['file_to_download'])
      const promise = this.id ? this.update(formData) : this.create(formData)
      promise.then(
        () => {
          setTimeout(() => {
            if (!this.id) {
              this.closeForm()
            } else {
              this.makeFormReadonly()
            }
          }, 500)
        }
      )
    }
  },
  validations: {
    form: {
      document_type: { required },
      subtype: { },
      acronym: { },
      name: { required },
      description: { },
      in_yard_check_list: { },
      in_yard_evaluation: { },
      validity_duration: { },
      ordering: { },
      contractor_summary_order: { },
      in_contractor_summary: { },
      product: {}
    }
  }
}
</script>
